import React, { useState, useEffect } from 'react';
import Modal from './Modal.js';
import logo from '../assets/full.jpg';
import es from '../assets/es.png'
import tg from '../assets/tg.png'
import tw from '../assets/tw.png'
import dx from '../assets/dx.png'
import cmc from '../assets/cmc.png'
import cg from '../assets/cg.png'
// import ct from '../assets/ct.png'
import top from '../assets/top.png'
import bottom from '../assets/bottom.png'
import fast from '../assets/fast.jpg'
import audioFile from '../assets/audio.mp3';
import mx from '../assets/mx.png';
import bm from '../assets/bm.png';

import '../App.css';

function App() {
  const [modalVisible, setModalVisible] = useState(true);
  const [audioPlaying, setAudioPlaying] = useState(false);

  // Use useEffect to show the modal when the component mounts
  useEffect(() => {
    setModalVisible(true);
  }, []);

  const closeModal = () => {
    setModalVisible(false);
    setAudioPlaying(true);
  };

  return (
    <div className="App">
      <div className='welcome'>
        <Modal show={modalVisible} onClose={closeModal} />
      </div>
      {audioPlaying && (
      <audio autoPlay loop>
        <source src={audioFile} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      )}
      <body>
      <div className='bga'>
          <a>
            <img src={top} alt="logo" className="App-logo" width='500vw'/>
          </a>
          <a>
            <img src={logo} alt="logo" className="App-logo" width='500vw'/>
          </a>
          <a>
            <img src={bottom} alt="logo" className="App-logob" width='500vw'/>
          </a>
        </div>
        <div className='bgb'>
          <a href='https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=0x793A5D8b30Aab326F83d20a9370C827fEA8Fdc51'>
            <button className='uni'>Buy on UniSwap</button>
          </a>
        </div>
        <div className='bge'>
        <p className='exc'>buy on exchange</p>
          <div className='container'>
            <a href='https://www.mexc.com/exchange/GIAC_USDT' className='exchange'>
              <img src={mx} width='40px' alt='mexc icon' className='left'/>
              <span>MEXC</span>
            </a>
            <a href='https://www.bitmart.com/trade/en-US?symbol=GIAC_USDT' className='exchange'>
              <img src={bm} width='40px' alt='bitmart icon' className='left'/>
              <span>BITMART</span>
            </a>
          </div>
        </div>
        <div className='bgc'>
        <h3>0x793a5d8b30aab326f83d20a9370c827fea8fdc51</h3>
          <div className='container'>
            <a href='https://etherscan.io/address/0x793a5d8b30aab326f83d20a9370c827fea8fdc51' className='item'>
              <img src={es} width='40px' alt='etherscan icon' className='left'/>
              <span>Etherscan</span>
            </a>
            <a href='https://www.dextools.io/app/en/ether/pair-explorer/0xa2bbbe7e2e48311830e41cb43814a821c4c2a16e?t=1710198926561' className='item'>
              <img src={dx} width='40px' alt='dextools icon' className='left'/>
              <span>DEXTools</span>
            </a>
            <a href='https://coinmarketcap.com/dexscan/ethereum/0xa2bbbe7e2e48311830e41cb43814a821c4c2a16e/' className='item'>
              <img src={cmc} width='40px' alt='cmc icon' className='left'/>
              <span>CoinMarketCap</span>
            </a>
          </div>
          <div className='container'>
            <a href='https://twitter.com/giac_eth' className='item'>
              <img src={tw} width='40px' alt='twitter icon' className='left'/>
              <span>Twitter</span>
            </a>
            <a href='https://t.me/gorillainacoupe_eth' className='item'>
              <img src={tg} width='40px' alt='telegram icon' className='left'/>
              <span>Telegram</span>
            </a>
            <a href='https://www.coingecko.com/en/coins/gorilla-in-a-coupe' className='item'>
              <img src={cg} width='50px' alt='coingecko icon' className='left'/>
            {/* Change src from ct to cg when updating this */}
              <span>CoinGecko</span>
            </a>
          </div>
          <p>never give up</p>
          <div class="con">
            <img src={fast} alt="Animated Image" class="animated-image" />
          </div>
        </div>
        <div className='bgd'>
          <h1>contact@giac.vip</h1>
          <h2>GIAC is a meme coin with no intrinsic value or expectation of financial return</h2>
          <h2>There is no formal team or roadmap</h2>
          <h2>The coin is completely useless and for entertainment purposes only</h2>
        </div>
    </body>
  </div>
  );
}

export default App;
